<template>
  <div class="view pa24">
    <el-dialog
      :title="title"
      width="800px"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :before-close="closeDialog"
      append-to-body
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="dealerRuleForm"
          label-width="100px"
          class="ruleForm"
        >
            <el-form-item :label="prefix" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="仓库编号" prop="warehouseCode">
                <el-input v-model="ruleForm.warehouseCode"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sorting">
              <el-input v-model="ruleForm.sorting" @input="handleInput"></el-input>
            </el-form-item>
            <el-form-item label="负责人" prop="leaderId" v-if="ruleForm.type===0">
              <el-select multiple v-model="ruleForm.userIds" placeholder="请选择" style="width: 100%">
                <el-option
                    v-for="(item, index) in options"
                    :key="index"
                    :label="item.name"
                    :value="item.userId"
                    @click.native="selectLeader(item)"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone" v-if="ruleForm.type===0">
              <el-input v-model="ruleForm.phone"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button class="btncss" @click="confirmAddDealer">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { addWarehouse } from '@/api/warehouseManage.js'
import {get} from "@/utils/request";
export default {
  components: {

  },
  data() {
    return {
      dialogVisible: false,
      title: '--',
      prefix: '--',
      ruleForm: {
         warehouseCode: '',
         name:'',
         sorting:0,
         phone:'',
         leaderId:'',
         leader:'',
         parentId: 0,
         type: 0,
         userIds:[]
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        sorting: [{ required: true, message: '请选择排序', trigger: 'blur' }],
      },
      id: '',
      options: []
    };
  },
  onShow() {

  },
  created() {
    this.getLeader()
    this.ruleForm.userIds = []
  },
  methods: {
    handleInput() {
      this.ruleForm.sorting = this.ruleForm.sorting.replace(/\D/g, '');
    },
    getLeader() {
      this.options = []
      get('/home/userSelect').then(res=>{
        this.options = res
      })
    },
    selectLeader(row){
      this.ruleForm.leader = row.name
      this.ruleForm.leaderId = row.userId
      console.log(row)
    },
    openDialog(type, data) {
      this.ruleForm.type = data.type
      this.ruleForm.parentId = data.parentId
      var prefix = '仓库';
      if( 1===this.ruleForm.type ){
        prefix = '库区';
      }else if( 2===this.ruleForm.type ){
        prefix = '区位';
      }
      this.prefix = prefix+"名称"
      if(type === 'add') {
        this.title = '添加'+prefix
        this.id = ''
        this.ruleForm.name = ''
        this.ruleForm.leaderId = ''
        this.ruleForm.leader = ''
        this.ruleForm.sorting = 0
        this.ruleForm.phone = ''
      }else {
        this.title = '编辑'+prefix
        this.id = data.id
        this.ruleForm.name = data.warehouseName
        this.ruleForm.leaderId = data.leaderId
        this.ruleForm.leader = data.leader
        this.ruleForm.sorting = data.sorting
        this.ruleForm.phone = data.phone
        this.ruleForm.warehouseCode = data.warehouseCode
        this.ruleForm.userIds = data.userIds
      }
      this.dialogVisible = true
    },
    closeDialog() {
      this.dialogVisible = false
      this.resetForm()
      this.$emit('getList')
    },
    resetForm() {
      this.$refs.dealerRuleForm.resetFields();
    },
    confirmAddDealer() {
      this.$refs.dealerRuleForm.validate((valid) => {
        if (valid) {
          let params = this.ruleForm
          params.id = this.id
          addWarehouse(params).then(res => {
            if(res.code === 200) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.closeDialog()
            }
          })
        }
      })
    },
  },


};
</script>

<style lang="scss" scoped>
.ml10{
  margin-left: 10rpx;
}
</style>
