<template>
    <div>
        <el-dialog
            :title="title"
            :visible.sync="childrenVisible"
            width="70%"
            @close="closeDialog"
            append-to-body
        >
          <div class="d-flex">
            <el-form :model="queryParam" style="width: 100%;" >
              <el-row >
                <el-col :span="6">
                  <el-form-item label-width="100px" label-position="left" class="fromItem">
                    <el-input v-model="queryParam.search" placeholder="请输入区位名称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col style="margin-left: 15px" :span="6">
                  <el-button type="primary" class="myBtn" @click="initList">搜索</el-button>
                  <el-button type="primary" class="myBtn" @click="handleAdd('add')">+添加区位</el-button>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <commonTable
              :tableData="tableData"
              :total="total"
              :currentPage="queryParam.pageNum"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
          >
            <template v-slot:table>

              <el-table-column
                  prop="sorting"
                  align="center"
                  label="排序"
              />

              <el-table-column
                  prop="warehouseName"
                  align="center"
                  label="区位名称"
              />
              <el-table-column
                  prop="boxNum"
                  align="center"
                  label="区位存量"
              >
                <template slot-scope="scope">
                  <el-button @click="showDetail(scope.row)" type="text">
                    {{scope.row.boxNum||0}}箱
                    <span v-if="scope.row.packNum">
                      +{{scope.row.packNum||0}}盒
                   </span>
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  align="center"
                  label="创建时间"
              />
              <el-table-column align="center" width="200" label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="edit(scope.row)">编辑</el-button>
                  <el-button type="text" @click="del(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </template>
          </commonTable>
          <add-warehouse-dialog ref="addDialog" @getList="initList"></add-warehouse-dialog>
          <warehouse-dialog ref="detailDialog"/>
        </el-dialog>
    </div>
</template>
<script>
import commonTable from "@/components/common/commonTable";
import {get} from "@/utils/request";
import AddWarehouseDialog from "./AddWarehouseDialog.vue";
import WarehouseDialog from "@/pages/dashboard/components/detailDialog/warehouseDialog.vue";
import { deleteWarehouse } from '@/api/warehouseManage.js'
export default {
    components: {
      commonTable,
      AddWarehouseDialog,
      WarehouseDialog
    },
    data() {
        return {
          title: '---',
          childrenVisible: false,
          tableData: [],
          loading: false,
          total: 1,
          item: {},
          queryParam: {
            pageSize: 10,
            pageNum: 1,
            type: 0,
            parentId: 0,
            search:''
          }
        }
    },
    methods: {
        handleAdd(type) {
          this.$refs.addDialog.openDialog(type,{'type':this.item.type+1,'parentId':this.item.id})
        },
        closeDialog() {
          this.childrenVisible = false
          this.$emit('getList')
        },
        handleSizeChange(num) {
            this.queryParam.pageSize = num;
            this.queryParam.pageNum = 1;
            this.getWarehouseList()
        },
        handleCurrentChange(num) {
            this.queryParam = num
            this.getWarehouseList()
        },
        openDialog(item) {
            this.title = item.warehouseName
            this.item = item
            this.childrenVisible = true
            this.$nextTick(()=>{
              this.queryParam.type = item.type + 1
              this.queryParam.parentId = item.id
              this.initList()
            })
        },
        showDetail(item) {
          item.isShow = true
          this.$refs.detailDialog.openDialog(item)
        },
        initList() {
          get('/crk/app/warehousePage',this.queryParam).then(res=>{
            this.tableData = res.list
            this.total = res.total
          })
        },
      edit(data) {
        this.$refs.addDialog.openDialog('edit', data)
      },
      del(data) {
        let params={
          id: data.id
        }
        this.$confirm('此操作将永久删除该区位, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteWarehouse(params).then(res => {
            if(res.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.initList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    }
}
</script>
