<template>
  <div class="view pa24">
    <div class="d-flex">
        <el-button class="ma" type="primary" @click="handleAddDealer()">+添加仓库</el-button>
    </div>
    <commonTable
      :tableData="tableData"
      :total="total"
      :currentPage="pageNum"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:table>

        <el-table-column
          prop="warehouseName"
          align="center"
          label="仓库名称"
        />
        <el-table-column
          prop="warehouseCode"
          align="center"
          label="仓库编号"
        >
        <template slot-scope="scope">
          {{ scope.row.warehouseCode || '--'}}
        </template>
        </el-table-column>
        <el-table-column
          prop="phone"
          align="center"
          label="联系电话"
        >
        <template slot-scope="scope">
          {{ scope.row.phone || '--'}}
        </template>
        </el-table-column>
        <el-table-column
            prop="children"
            align="center"
            label="分区数量"
            v-if="false"
        >
          <template slot-scope="scope">
            <el-button @click="showChildren(scope.row)" type="text">
              <span>{{scope.row.children||0}}</span>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
            prop="boxNum"
            align="center"
            label="剩余数量"
        >
          <template slot-scope="scope">
             <el-button type="text">
               {{scope.row.boxNum||0}}箱
               <span v-if="scope.row.packNum">
                +{{scope.row.packNum||0}}盒
             </span>
             </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="leader"
          align="center"
          label="负责人"
        >
        <template slot-scope="scope">
          {{ scope.row.leader || '--' }}
        </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          align="center"
          label="创建时间"
        />
        <el-table-column align="center" width="200" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="editDealar(scope.row)">编辑</el-button>
            <el-button type="text" @click="deleteDealar(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <add-warehouse-dialog ref="addDealerDialog" @getList="getList"></add-warehouse-dialog>
    <warehouse-children ref="childrenVisible" @getList="getList"></warehouse-children>
    <warehouse-dialog ref="detailDialog"/>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { deleteWarehouse } from '@/api/warehouseManage.js'
import AddWarehouseDialog from "./components/AddWarehouseDialog.vue";
import WarehouseChildren from "./components/WarehouseChildren.vue";
import {get} from "@/utils/request";
import WarehouseDialog from "@/pages/dashboard/components/detailDialog/warehouseDialog.vue";
export default {
  name: "dealerList",
  components: {
    WarehouseDialog,
    commonTable,
    AddWarehouseDialog,
    WarehouseChildren
  },
  data() {
    return {
        tableData: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
    };
  },
  created() {
    this.getList()
  },
  methods: {
    showDetail(item) {
      this.$refs.detailDialog.openDialog(item)
    },
    showChildren(item){
      this.$refs.childrenVisible.openDialog(item)
    },
      handleSizeChange(num) {
        this.pageSize = num;
        this.pageNum = 1;
        this.getList();
      },
      handleCurrentChange(num) {
        this.pageNum = num;
        this.getList();
      },
      handleAddDealer() {
          this.$refs.addDealerDialog.openDialog("add", {'type':0,'parentId':0})
      },
      getList() {
        let params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          parentId: 0,
          type: 0,
        }
        get('/crk/app/warehousePage',params).then(res=>{
          this.tableData = res.list
          this.total = res.total
        })
      },
      editDealar(data) {
        this.$refs.addDealerDialog.openDialog('edit', data)
      },
      deleteDealar(data) {
        let params={
          id: data.id
        }
        this.$confirm('此操作将永久删除该仓库, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteWarehouse(params).then(res => {
            if(res.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      }
  }
}
</script>

<style lang="scss" scoped></style>

