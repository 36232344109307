<template>
    <div>
        <el-dialog :title="title"
                   :visible.sync="childrenVisible"
                   width="70%"
                   append-to-body
                   @close="closeDialog">
          <div class="d-flex">
            <el-button class="ma" type="primary" @click="handleAddDealer('add')">+添加区</el-button>
          </div>
          <commonTable
              :tableData="tableData"
              :total="total"
              :currentPage="pageNum"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
          >
            <template v-slot:table>

              <el-table-column
                  prop="warehouseName"
                  align="center"
                  label="分区名称"
              />
              <el-table-column
                  prop="children"
                  align="center"
                  label="分位数量"
              >
                <template slot-scope="scope">
                  <el-button @click="showChildren(scope.row)" type="text">
                    <span>{{scope.row.children||0}}</span>
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                  prop="boxNum"
                  align="center"
                  label="剩余数量"
              >
                <template slot-scope="scope">
                  <el-button @click="showDetail(scope.row)" type="text">
                    {{scope.row.boxNum||0}}箱
                    <span v-if="scope.row.packNum">
                +{{scope.row.packNum||0}}盒
             </span>
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                  prop="leader"
                  align="center"
                  label="负责人"
              />
              <el-table-column
                  prop="createTime"
                  align="center"
                  label="创建时间"
              />
              <el-table-column align="center" width="200" label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="editDealar(scope.row)">编辑</el-button>
                  <el-button type="text" @click="deleteDealar(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </template>
          </commonTable>
          <add-warehouse-dialog ref="addDealerDialog" @getList="initList"></add-warehouse-dialog>
          <warehouse-second-children ref="childrenVisible" @getList="initList"></warehouse-second-children>
          <warehouse-dialog ref="detailDialog"/>
        </el-dialog>
    </div>
</template>
<script>
import commonTable from "@/components/common/commonTable";
import {get} from "@/utils/request";
import AddWarehouseDialog from "./AddWarehouseDialog.vue";
import WarehouseDialog from "@/pages/dashboard/components/detailDialog/warehouseDialog.vue";
import WarehouseSecondChildren from "./WarehouseSecondChildren.vue";
import { deleteWarehouse } from '@/api/warehouseManage.js'
export default {
    components: {
      WarehouseSecondChildren,
      commonTable,
      AddWarehouseDialog,
      WarehouseDialog
    },
    data() {
        return {
          title: '---',
          childrenVisible: false,
          tableData: [],
          loading: false,
          total: 1,
          item: {},
          queryParam: {
            pageSize: 10,
            pageNum: 1,
            type: 0,
            parentId: 0
          }
        }
    },
    methods: {
        handleAddDealer(type) {
          this.$refs.addDealerDialog.openDialog(type,{'type':this.item.type+1,'parentId':this.item.id})
        },
        closeDialog() {
          this.childrenVisible = false
          this.$emit('getList')
        },
        handleSizeChange(num) {
            this.queryParam.pageSize = num;
            this.queryParam.pageNum = 1;
            this.getWarehouseList()
        },
        handleCurrentChange(num) {
            this.pageNum = num
            this.getWarehouseList()
        },
        openDialog(item) {
            this.title = item.warehouseName
            this.item = item
            this.childrenVisible = true
            this.$nextTick(()=>{
              this.queryParam.type = item.type + 1
              this.queryParam.parentId = item.id
              this.initList()
            })
        },
        showDetail(item) {
          this.$refs.detailDialog.openDialog(item)
        },
        showChildren(item){
          this.$refs.childrenVisible.openDialog(item)
        },
        initList() {
          get('/crk/app/myWarehouse',this.queryParam).then(res=>{
            this.tableData = res
          })
        },
      editDealar(data) {
        this.$refs.addDealerDialog.openDialog('edit', data)
      },
      deleteDealar(data) {
        let params={
          id: data.id
        }
        this.$confirm('此操作将永久删除该区, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteWarehouse(params).then(res => {
            if(res.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.initList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      }
    }
}
</script>
