import httpUtil from "@/utils/httpUtil";

/***仓库统计 */
export const myWarehouse = params => httpUtil.get("/crk/app/myWarehouse", params);

// 出入库统计
export const getCrkStastics = params => httpUtil.get("/crk/app/getCrkStastics", params);

//出入库折线图
export const selectCrkLine = params => httpUtil.get("/crk/app/selectCrkLine", params);

//下载App
export const crkVersion = params => httpUtil.post("/app/cur/version", params);

//仓库列表
export const warehouseList = params => httpUtil.get("/crk/app/warehouseList", params);

//库房详情
export const warehouseDetail = params => httpUtil.get("/crk/app/warehouseDetail", params);

//出入库记录
export const crkBatchPage = params => httpUtil.get("/crk/app/crkBatchPage", params);

//出入库详情
export const warehouseLog = params => httpUtil.get("/crk/app/warehouseLog", params);

//关联记录批次列表
export const bindLog = params => httpUtil.get("/crk/app/crkBindPage", params);
//获取关联箱子信息
export const crkBindBoxList = params => httpUtil.get("/crk/app/crkBindBoxList", params);
