<template>
    <div>
<!-- :append-to-body="true" :modal-append-to-body="false" -->
        <el-dialog :title="title" :visible.sync="outerVisible" width="70%" append-to-body>
          <el-tabs v-model="activeName" @tab-click="tabClick">
            <el-tab-pane label="商品列表" name="list">
              <div class="mt10">
                <WarehouseDetail ref="detail" :search-config="searchConfig"/>
              </div>
            </el-tab-pane>
            <el-tab-pane label="分类查看" name="class">
              <WarehouseClassDetail ref="class" :search-config="searchConfig"/>
            </el-tab-pane>
          </el-tabs>
<!--            <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"-->
<!--                @handleCurrentChange="handleCurrentChange" :currentPage="pageNum" :total="total">-->
<!--                <template v-slot:table>-->
<!--                    &lt;!&ndash; <el-table-column align="center" prop="title" label="产品名称">-->
<!--                    </el-table-column> &ndash;&gt;-->
<!--                    <el-table-column align="center" prop="className" label="产品类别">-->
<!--                    </el-table-column>-->
<!--                    <el-table-column align="center" label="库存">-->
<!--                        <template slot-scope="scope">-->
<!--                            <span>{{ scope.row.boxNum ? scope.row.boxNum : 0 }}箱</span>-->
<!--                            <span v-if="scope.row.packNum">+{{ scope.row.packNum }}盒</span>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                    <el-table-column align="center" label="操作">-->
<!--                        <template slot-scope="scope">-->
<!--                            <span class="lookItem" @click="lookItem(scope.row)">查看</span>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                </template>-->

<!--            </commonTable>-->
<!--            <el-dialog title="库房详情" :visible.sync="innerVisible" append-to-body>-->
<!--                <commonTable :tableData="tableData1" :loading="loading1" @handleSizeChange="handleSizeChange1"-->
<!--                    @handleCurrentChange="handleCurrentChange1" :currentPage="pageNum1" :total="total1">-->
<!--                    <template v-slot:table>-->
<!--                        &lt;!&ndash; <el-table-column align="center" prop="title" label="产品名称">-->
<!--                        </el-table-column> &ndash;&gt;-->
<!--                        <el-table-column align="center" label="产品类别">-->
<!--                            <template slot-scope="scope">-->
<!--                                <span>{{ scope.row.className }}</span>-->
<!--                                <span :class="scope.row.type == 3 ? 'name_status' : 'name_status1'">{{ scope.row.type == 3 ?-->
<!--                                    '箱'-->
<!--                                    : '盒' }}</span>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column align="center" label="编码">-->
<!--                            <template slot-scope="scope">-->
<!--                                <div style="display: flex;align-items: center;justify-content: center;">-->
<!--                                    <span>{{ scope.row.handle }}</span>-->
<!--                                    <img class="copyImg" src="../../../../assets/images/fz.png"-->
<!--                                        @click="copy(scope.row.handle)" />-->
<!--                                </div>-->

<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                    </template>-->

<!--                </commonTable>-->
<!--            </el-dialog>-->
        </el-dialog>
    </div>
</template>
<script>
import { warehouseList, warehouseDetail } from "@/api/crk";
import commonTable from "@/components/common/commonTable";
import WarehouseDetail from "@/pages/dashboard/components/detailDialog/WarehouseDetail.vue";
import WarehouseClassDetail from "@/pages/dashboard/components/detailDialog/WarehouseClassDetail.vue";
export default {
    props:{

    },
    components: {
      WarehouseClassDetail,
      WarehouseDetail
    },
    data() {
        return {
          title: '我的库房',
          searchConfig: {
            showSearch:false
          },
          outerVisible: false,
          innerVisible: false,
          tableData: [],
          loading: false,
          pageSize: 10,
          pageNum: 1,
          total: 1,
          tableData1: [],
          loading1: false,
          pageSize1: 10,
          pageNum1: 1,
          total1: 1,
          id: null,
          classId: null,
          activeName: "list",
          type: 0
        }
    },
    methods: {
        tabClick(e){
           this.activeName=e.name;
           if (this.activeName==='class'){
             this.$refs.class.queryParam = {}
             if( 0===this.type ){
               this.$refs.class.queryParam.warehouseId=this.id
             }else if( 1===this.type ){
               this.$refs.class.queryParam.regionId=this.id
             }else if( 2===this.type ){
               this.$refs.class.queryParam.localId=this.id
             }
             this.$refs.class.queryParam.status=1
             this.$refs.class.initList()
           }else {
               this.$refs.detail.queryParam.pageNum = 1
               this.$refs.detail.queryParam.page = 1
               this.$refs.detail.initList()
           }
        },

        copy(context) {
            // 创建输入框元素
            let oInput = document.createElement('input');
            // 将想要复制的值
            oInput.value = context;
            // 页面底部追加输入框
            document.body.appendChild(oInput);
            // 选中输入框
            oInput.select();
            // 执行浏览器复制命令
            document.execCommand('Copy');
            // 弹出复制成功信息
            this.$message.success('复制成功');
            // 复制后移除输入框
            oInput.remove();
        },
        closeDialog() {
            this.scanDialogVisible = false
        },
        handleSizeChange(num) {
            this.pageSize = num;
            this.pageNum = 1;
            this.getWarehouseList()
        },
        handleCurrentChange(num) {
            this.pageNum = num
            this.getWarehouseList()
        },
        openDialog(item) {
          if(item.warehouseName){
            this.title = item.warehouseName
          }
          if(item.isShow){
            this.searchConfig.showSearch = true
          }
            this.type = item.type
            this.id = item.id
            this.activeName="list"
            this.outerVisible = true
            this.$nextTick(()=>{
              this.$refs.detail.queryParam = {}
              if( 0===this.type ){
                this.$refs.detail.queryParam.warehouseId=item.id
              }else if( 1===this.type ){
                this.$refs.detail.queryParam.regionId=item.id
              }else if( 2===this.type ){
                this.$refs.detail.queryParam.localId=item.id
              }
              this.$refs.detail.queryParam.status=1
              this.$refs.detail.queryParam.pageNum = 1
              this.$refs.detail.initList()
            })
        },
        getWarehouseList() {
          const param = {};
          if( 0===this.type ){
            param.warehouseId = this.id
          }else if( 1===this.type ){
            param.regionId = this.id
          }else if( 2===this.type ){
            param.localId = this.id
          }
          warehouseList(param).then(res => {
                  this.tableData = res.data
          })
        },
        lookItem(item) {
            this.innerVisible = true
            this.classId = item.classId
            const param = {
              pageNum: this.pageNum1,
              pageSize: this.pageSize1,
              classId: this.classId,
            };
            if( 0===this.type ){
              param.warehouseId = this.id
            }else if( 1===this.type ){
              param.regionId = this.id
            }else if( 2===this.type ){
              param.localId = this.id
            }
            warehouseDetail(param).then(res => {
                if (res.code == 200 && res.data.list.length) {
                    this.tableData1 = res.data.list
                    this.total1 = res.data.total
                }else {
                    this.tableData1 = []
                }
            })
        },
        handleSizeChange1(num) {
            this.pageSize1 = num;
            this.pageNum1 = 1;
            this.lookItem()
        },
        handleCurrentChange1(num) {
            this.pageNum1 = num
            this.lookItem()
        },
    }
}
</script>
<style scoped>
.lookItem {
    color: #409eff;
    cursor: pointer;
}

.name_status {
    display: inline-block;
    width: 26px;
    height: 17px;
    line-height: 17px;
    background: rgba(63, 114, 246, 0.11);
    border: 1px solid #3F72F6;
    border-radius: 4px;
    font-size: 24rpx;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #3F72F6;
    text-align: center;
    margin-left: 10px;
}

.name_status1 {
    display: inline-block;
    width: 26px;
    height: 17px;
    line-height: 17px;
    background: rgba(243, 136, 46, 0.11);
    border: 1px solid #F3882E;
    border-radius: 4px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #F3882E;
    text-align: center;
    margin-left: 10px;
}

.copyImg {
    margin-left: 10px;
    cursor: pointer;
}
</style>
